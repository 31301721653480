import { useEffect, useState } from 'react';
import type { AppProps } from 'next/app';
import { WagmiConfig, useAccount } from 'wagmi';
import { chains, wagmiClient } from '@/modules/providers/wagmi';
import { darkTheme, RainbowKitProvider, Chain } from '@rainbow-me/rainbowkit';
import '@rainbow-me/rainbowkit/styles.css';
import { DaiBalanceContextProvider } from '../modules/dai/context/DaiBalance.context';
import { AnimaBalanceProvider } from '../modules/dai/context/AnimaBalance.context';
import { RarityItemBalanceProvider } from '../modules/dai/context/RarityItemBalance.context';
import { TransactionDataProvider } from '../modules/dai/context/FightsData.context';
import { chain } from 'wagmi';

export default function App({ Component, pageProps }: AppProps) {
  const [backgroundImage, setBackgroundImage] = useState('');
  const [compositeBackground, setCompositeBackground] = useState('');
  const { address } = useAccount();

  useEffect(() => {
    const randomImageNumber = Math.floor(Math.random() * 34) + 1;
    const imageUrl = `/images/${randomImageNumber}.jpg`;

    const img = new Image();
    img.src = imageUrl;
    img.crossOrigin = 'Anonymous';
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const imgWidth = img.width;
      const imgHeight = img.height;
      canvas.width = imgWidth * 3;
      canvas.height = imgHeight;

      if (ctx) {
        ctx.save();
        ctx.scale(-1, 1);
        ctx.drawImage(img, -imgWidth, 0, imgWidth, imgHeight);
        ctx.restore();
        ctx.drawImage(img, imgWidth, 0, imgWidth, imgHeight);
        ctx.save();
        ctx.scale(-1, 1);
        ctx.drawImage(img, -imgWidth * 3, 0, imgWidth, imgHeight);
        ctx.restore();
        const compositeImageUrl = canvas.toDataURL();
        setCompositeBackground(compositeImageUrl);
      }
    };
  }, []);

  return (
    <WagmiConfig client={wagmiClient}>
      {/* @ts-ignore */}
      <RainbowKitProvider chains={chains} theme={darkTheme()} initialChain={chain.arbitrum as Chain}>
        {/* @ts-ignore */}
        <DaiBalanceContextProvider>
          {/* @ts-ignore */}
          <AnimaBalanceProvider>
            {/* @ts-ignore */}
            <RarityItemBalanceProvider>
              {/* @ts-ignore */}
                <TransactionDataProvider address={address}>
                  {/* @ts-ignore */}
                  <Component {...pageProps} />
                </TransactionDataProvider>
            </RarityItemBalanceProvider>
          </AnimaBalanceProvider>
        </DaiBalanceContextProvider>
        <style jsx global>{`
          :root {
            --gap: 16pt;
            --black-background: black;
            --page-background-color: white;
            --primary: blue;
          }

          * {
            scroll-behavior: smooth;
          }

          @font-face {
            font-family: 'FT Polar Trial';
            src: url('/fonts/FTPolarTrial-Medium.woff2');
            font-weight: normal;
          }

          @font-face {
            font-family: 'FT Polar Trial';
            src: url('/fonts/FTPolarTrial-Bold.woff2');
            font-weight: bold;
          }

          body {
            font-family: 'FT Polar Trial', 'Helvetica Neue', sans-serif;
            margin: 0;
            padding: 0;
            color: var(--text-main-color);
            height: 100vh;
            background-image: url('${compositeBackground}');
            background-size: auto 100vh;
            background-position: center;
            background-repeat: no-repeat;
            background-attachment: fixed;
            position: relative;
          }

          body::before {
            content: '';
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(255, 255, 255, 0.3); /* Adjust opacity as needed */
            pointer-events: none; /* Ensure it doesn't interfere with other content */
            z-index: 1;
          }

          #__next {
            position: relative;
            z-index: 2;
          }

          * {
            box-sizing: border-box;
          }

          img {
            max-width: 100%;
          }
        `}</style>
      </RainbowKitProvider>
    </WagmiConfig>
  );
}





















