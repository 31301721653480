import React, { useEffect, useState } from 'react';
import { fetchPrices } from '../api/getCryptoPrices'; // Importowanie funkcji z getCryptoprices.ts

interface Prices {
  [key: string]: {
    usd: number;
  };
}

const CryptoPrices: React.FC = () => {
  const [prices, setPrices] = useState<Prices>({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const getPrices = async () => {
      try {
        const data = await fetchPrices();
        setPrices(data);
        setLoading(false);
      } catch (err) {
        setError(err instanceof Error ? err : new Error('Unknown error')); // Sprawdzenie typu err
        setLoading(false);
      }
    };

    getPrices();
  }, []);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;


  const AMRatio = Math.floor(prices.magic?.usd && prices.anima?.usd
  ? prices.magic.usd / prices.anima.usd
  : 1);

  return (
    <div style={{backgroundColor: 'rgba(255, 255, 255, 0.2)', padding: '5px' ,borderRadius: '6px'}}>
     <div style={{ textAlign: 'center', fontWeight: 'bold', backgroundColor: 'rgba(255, 255, 255, 0.25)' }}>Crypto prices (USD)</div>
     <ul style={{ fontSize: '90%' }}>
      <li>Arbitrum: {prices.arbitrum?.usd?.toFixed(4)}</li>
      <li>MAGIC: {prices.magic?.usd?.toFixed(4)}</li>
      <li>Anima: {prices.anima?.usd?.toFixed(4)}</li>
      <li>
        MAGIC:Anima
        <br />
        Ratio 1 : {AMRatio}
      </li>
    </ul>

    </div>
  );
};

export default CryptoPrices;