import React, { createContext, useEffect, useState, ReactNode, useCallback } from 'react';
import { ethers } from 'ethers';
import { useAccount, useProvider } from 'wagmi';
import rarityItemAbi from '../../../eth-sdk/abis/rarityItem.json';

interface RarityItemBalanceContextProps {
  balances: { [id: number]: number };
  loading: boolean;
  refresh: (address: string) => Promise<void>;
  setBalances: React.Dispatch<React.SetStateAction<{ [id: number]: number }>>;
}

const RarityItemBalanceContext = createContext<RarityItemBalanceContextProps>({
  balances: {},
  loading: true,
  refresh: async () => {},
  setBalances: () => {}
});

interface RarityItemBalanceProviderProps {
  children: ReactNode;
}

export const RarityItemBalanceProvider = ({ children }: RarityItemBalanceProviderProps) => {
  const [balances, setBalances] = useState<{ [id: number]: number }>({});
  const [loading, setLoading] = useState(true);
  const { address } = useAccount();
  const provider = useProvider();

  const fetchBalances = useCallback(async (addr: string) => {
    if (addr) {
      console.log('Fetching balances for address:', addr);
      const rarityItemContract = new ethers.Contract(
        '0x8deCc5d594Da4e6e2D474cBcCAA111081EFAcA40',
        rarityItemAbi,
        provider
      );

      try {
        const ids = Array.from({ length: 128 }, (_, i) => i + 1);
        const accounts = new Array(ids.length).fill(addr);

        const batchBalances = await rarityItemContract.balanceOfBatch(accounts, ids);

        const fetchedBalances: { [id: number]: number } = {};
        batchBalances.forEach((balance: ethers.BigNumber, index: number) => {
          if (balance.gt(0)) {
            fetchedBalances[ids[index]] = balance.toNumber();
          }
        });

        console.log('Fetched Balances:', fetchedBalances);
        setBalances(fetchedBalances);
      } catch (error) {
        console.error('Error fetching balances:', error);
      }

      setLoading(false);
    }
  }, [provider]);

  useEffect(() => {
    if (address) {
      fetchBalances(address);
    }
  }, [address, fetchBalances]);

  return (
    <RarityItemBalanceContext.Provider value={{ balances, loading, refresh: fetchBalances, setBalances }}>
      {children}
    </RarityItemBalanceContext.Provider>
  );
};

export const useRarityItemBalance = () => React.useContext(RarityItemBalanceContext);











