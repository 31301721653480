export const API_BASE_URL='https://proxy.getanima.org';


import { AdventurerType } from '../types/adventurer';
import peripheralsData from './minified_peripherals.json';
import peripheralsBattleData from './minified_peripherals_battle.json';



// BattleVersusV3 contract: 0x2cfcaff3289142E79173B856293D6128B6bD05c6 


export enum Rarity {
  Common = "Common",
  Rare = "Rare",
  Epic = "Epic",
  Legendary = "Legendary"
}

export const masteryCoefficients: Record<Rarity, { coefficient: number, reverseCoefficient: number }> = {
  [Rarity.Common]: { coefficient: 3, reverseCoefficient: 5 },
  [Rarity.Rare]: { coefficient: 5, reverseCoefficient: -1 },
  [Rarity.Epic]: { coefficient: 10, reverseCoefficient: -1 },
  [Rarity.Legendary]: { coefficient: 20, reverseCoefficient: -1 },
};

export const rarityThresholds: Record<Rarity, number> = {
  [Rarity.Common]: 3,
  [Rarity.Rare]: 6,
  [Rarity.Epic]: 11,
  [Rarity.Legendary]: 16,
};

export interface Attribute {
  trait_type: string;
  value: string;
}

export interface PeripheralMetadata {
  name: string;
  description: string;
  image: string;
  attributes: Attribute[];
}

export const PERIPHERALS: { [key: string]: PeripheralMetadata } = peripheralsData;

export type BoostStat = keyof AdventurerType;

interface Boost {
  type: string;
  stat: string;
  value: number;
  affinity?: {
    stats: { stat: string; weight: number }[];
    range: {
      min: number;
      mid: number;
      max: number;
    };
  };
}

export interface BoostContainer {
  boost: Boost;
}

export interface PeripheralBattleStats {
  boosts: BoostContainer[];
}

export const PERIPHERALS_BATTLE: { [key: string]: PeripheralBattleStats } = peripheralsBattleData;

