import React, { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { fetchFightsData } from '../../adventurers/api/fetchFightsData';

interface TransactionData {
  date: string;
  animaSum: number;
  transactionFeeSum: number;
  renownSum?: number;  // Dodajemy pole renownSum jako opcjonalne
  lootboxesCount?: number;
  passiveRenown?: number;
  renownS?:number;
  ratioH?:number;
}

interface TransactionDataContextProps {
  data: TransactionData[];
}

const TransactionDataContext = createContext<TransactionDataContextProps>({ data: [] });

interface TransactionDataProviderProps {
  children: ReactNode;
  address: string;
}

export const TransactionDataProvider = ({ children, address }: TransactionDataProviderProps) => {
  const [data, setData] = useState<TransactionData[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      if (address) {
        const result = await fetchFightsData(address);
        setData(result);
      }
    };

    fetchData();
  }, [address]);

  return (
    <TransactionDataContext.Provider value={{ data }}>
      {children}
    </TransactionDataContext.Provider>
  );
};

export const useTransactionData = () => useContext(TransactionDataContext);



