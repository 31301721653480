import React from 'react';

const Loader: React.FC = () => {
  return (
    <div className="loader">
      <img src="/images/progress.gif" alt="Loading..." />
      <style jsx>{`
        .loader {
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 2000;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .loader img {
          width: 50px; /* Zmniejszono szerokość o połowę */
          height: 50px; /* Zmniejszono wysokość o połowę */
        }
      `}</style>
    </div>
  );
};

export default Loader;