import axios from 'axios';
import { API_BASE_URL } from '../constants/constants';
import { currentEpoch } from '../utils/utils';

// Typ dla zwracanych danych (data i suma renown)
interface RenownSummary {
  date: string;  // Data w formacie YYYY-MM-DD
  renownSum: number;  // Suma attackerRenown dla danego dnia
  lootboxesCount: number;
}

// Funkcja pomocnicza do konwersji epoki na datę (bez godziny)
const epochToDate = (fightEpoch: number): string => {
    const date = new Date(fightEpoch * 24 * 60 * 60 * 1000); // Konwersja dni od epoki Unix (w ms)
    return date.toISOString().split('T')[0]; // Zwracamy datę w formacie YYYY-MM-DD
  };

export const loadBattlePvpData = async (creatorAddress: string): Promise<RenownSummary[]> => {
  const pageSize = 1000;
  let offset = 0;
  let hasMoreData = true;
  let allData: { dateEpoch: number; renownSum: string; lootboxesCount: number }[] = []; // attackerRenown as string
  const addressLowerCase = creatorAddress ? (`0x${creatorAddress.slice(2).toLowerCase()}` as `0x${string}`) : undefined;

  const URLI=`${API_BASE_URL}?endpoint=realmUsersStats`

  while (hasMoreData) {
    try {
      const response = await axios.post(URLI, {
        endpoint: 'realmUsersStats' ,
        query: `
          query advRenowns($first: Int!, $skip: Int!, $creator: String!, $epochStart: String!) {
            dailySummaries(first: $first, skip: $skip, where: {
              fromAddress: $creator,
              dateEpoch_gt: $epochStart
            }, orderBy: dateEpoch, orderDirection: asc) {
              dateEpoch
              renownSum
              lootboxesCount
            }
          }
        `,
        variables: {
          first: pageSize,
          skip: offset,
          creator: addressLowerCase,
          epochStart: (currentEpoch() - 32).toString() // Convert to string// Ograniczenie do ostatnich 31 dni
        }
       
      });

      const responseData = response.data;

      // Logowanie danych pobranych przez API
      console.log('Raw API response data:', responseData);

      if (responseData && responseData.data && responseData.data.dailySummaries) {
        const fetchedData = responseData.data.dailySummaries

        if (fetchedData.length < pageSize) {
          hasMoreData = false;
        }

        allData = [...allData, ...fetchedData];
        offset += pageSize;
      } else {
        console.error('Unexpected Renown GraphQL response structure:', responseData);
        hasMoreData = false;
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      hasMoreData = false;
    }
  }

  const processedData = allData.map(item => {
    // Konwersja renownSum na liczbę i podział przez 1000
    const renownSum = Number(item.renownSum) / 1000;

    // Konwersja lootboxesCount na liczbę
    const lootboxesCount = Number(item.lootboxesCount);

    // Zwracamy przetworzony obiekt
    return {
      date: epochToDate(Number(item.dateEpoch)), // Zachowujemy datę epoch
      renownSum, // Przetworzona wartość renownSum
      lootboxesCount // Przetworzona wartość lootboxesCount
    };
});

// Logowanie po przetworzeniu danych
console.log('Processed data:', processedData);

// Zwracamy przetworzony wynik
console.log('Final result (date, renownSum, lootboxesCount):', processedData);

return processedData;



};