// /api/getAttackEpoch.ts
import axios from 'axios';
import { API_BASE_URL } from '../constants/constants';
const graphEndpoint = API_BASE_URL; // Replace this with your actual GraphQL endpoint

/**
 * Poll the attackEpoch of a specific adventurer until it's updated to today's epoch or timeout is reached.
 * @param ownerAddress The address of the adventurer owner.
 * @param tokenId The tokenId of the adventurer.
 * @param pollInterval The interval between requests (in milliseconds). Default is 3000 ms.
 * @param maxPollTime The maximum time to poll before giving up (in milliseconds). Default is 60000 ms.
 * @returns {Promise<void>} Resolves when the attackEpoch is updated, rejects if it times out.
 */
export const pollAttackEpoch = async (
  ownerAddress: string,
  tokenId: number,
  pollInterval: number = 3000,
  maxPollTime: number = 120000
): Promise<void> => {
  const currentEpoch = Math.floor(Date.now() / (24 * 60 * 60 * 1000)); // Calculate today's epoch

  const fetchAttackEpoch = async (): Promise<number | null> => {
    try {
      const response = await axios.post(graphEndpoint, {
        operationName: 'Adventurers',
        query: `
          query Adventurers {
            adventurers(where: { 
              address: "${ownerAddress}",
              tokenId: ${tokenId}
            }) {
              attackEpoch
            }
          }
        `,
      });

      const adventurer = response.data?.data?.adventurers?.[0];
      if (adventurer && adventurer.attackEpoch !== undefined) {
        return adventurer.attackEpoch;
      }
      return null;
    } catch (error) {
      console.error('Error fetching attackEpoch:', error);
      return null;
    }
  };

  const startTime = Date.now();

  while (Date.now() - startTime < maxPollTime) {
    const attackEpoch = await fetchAttackEpoch();
    if (attackEpoch !== null && attackEpoch >= currentEpoch) {
      console.log('attackEpoch updated successfully.');
      return;
    }

    // Wait before polling again
    await new Promise((resolve) => setTimeout(resolve, pollInterval));
  }

  console.error('attackEpoch not updated within the allowed time.');
  throw new Error('attackEpoch update failed.');
};
