import React from 'react';
import { useAnimaBalance } from '../context/AnimaBalance.context';
import { formatUnits } from 'ethers/lib/utils';

const AnimaBalanceDisplay: React.FC = () => {
    const { balance } = useAnimaBalance();
    const formattedBalance = balance ? parseFloat(formatUnits(balance, 18)).toFixed(3) : '';
  
    return (
      <div className="anima-balance" style={{ color: 'white', marginRight: '15px', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
        <img src="https://arbiscan.io/token/images/realmland_32.png?v=2" alt="Anima Icon" style={{ marginRight: '5px', width: '16px', height: '16px' }} />
        {formattedBalance}
      </div>
    );
  };
  
  export default AnimaBalanceDisplay;