import React, { useState, useEffect } from 'react';
import { API_BASE_URL } from '../constants/constants';
import { useTransactionData } from '../../dai/context/FightsData.context';
import { avgLPBoostPercentage} from '../utils/utils';
import { AdventurerType } from '../types/adventurer';
import { ALLOWED_ADDRESSES } from '../../../pages/index';
import { ethers } from 'ethers'; // Import ethers.js
import AnimaAbi from '../../../eth-sdk/abis/anima.json'; // Import the Anima contract ABI
import { ExternalProvider } from '@ethersproject/providers'; // Import the ExternalProvider type from ethers.js
import axios from 'axios';  // Import axios for making API requests

interface FightsDataTableProps {
  cryptoPrices: { [key: string]: { usd: number } };
  address: string;
  adventurers: AdventurerType[];
}

const FightsDataTable: React.FC<FightsDataTableProps> = ({ cryptoPrices, address, adventurers }) => {
  
  const { data } = useTransactionData();
  const [isLoading, setIsLoading] = useState(true); // Set loading state
  useEffect(() => {
    if (data && data.length > 0) {
      setIsLoading(false); // Set loading to false after data is loaded
    }
  }, [data]);

  console.log('Transaction Stats Data:', data);

  let adventurerRecord = ALLOWED_ADDRESSES.find(
    entry => entry.address.toLowerCase() === address.toLowerCase() 
  );

  let validUntil = adventurerRecord?.validUntil;
  console.log('valid Until:', validUntil);

  const animaPrice = cryptoPrices.anima?.usd || 1;

  const totalAnimaSum = data.reduce((sum, item) => sum + item.animaSum, 0);
  const totalTransactionFeeSum = data.reduce((sum, item) => sum + item.transactionFeeSum, 0);
  const totalRenownSum = data.reduce((sum, item) => sum + (item.renownSum ?? 0), 0);
  const totalLootboxesCount = data.reduce((sum, item) => sum + (item.lootboxesCount ?? 0), 0);
  
  console.log("Data Passive Array:", data);

  
  const totalPassiveRenownSum = data.reduce((sum, item) => sum + (item.passiveRenown || 0), 0);

  const totalNetAnimaSum = totalAnimaSum - totalTransactionFeeSum / animaPrice;

  const LPBoostPercent = Math.floor(100 * avgLPBoostPercentage(address, adventurers)) / 100;
  const totalNetAnimaLpBoostSum = totalNetAnimaSum * (1 - LPBoostPercent);

  const displayAvgLPBoost = LPBoostPercent !== 0;

  let next30DaysSubsPrice = Math.round((displayAvgLPBoost ? totalNetAnimaLpBoostSum : totalNetAnimaSum) * 0.0325);

  const animaContractAddress = "0xcCd05A0fcfc1380e9Da27862Adb2198E58e0D66f"; // Replace with actual Anima contract address
  const yourWalletAddress = "0x9500f4696eF9c79D53c2B8803236FbC849943678"; // Your wallet address

  // Helper function to remove time part from a date
  const stripTime = (date: Date): Date => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  };

  const currentDate = stripTime(new Date());
  const validUntilDate = validUntil ? stripTime(new Date(validUntil)) : null;

  // Check if validUntil date exists and if the difference is 3 days or less
  const shouldShowExtendButton = validUntilDate && (validUntilDate.getTime() - currentDate.getTime()) <= 1 * 24 * 60 * 60 * 1000;

  // Function to check if validUntil is a valid date string
  const isValidDate = (dateString: string | undefined): boolean => {
    return dateString ? !isNaN(Date.parse(dateString)) : false;
  };

  const handleExtendSubscription = async () => {
    try {
      // Check if MetaMask is installed
      if (!window.ethereum) {
        alert("Please install MetaMask!");
        return;
      }

      // Request access to MetaMask account
      await window.ethereum.request({ method: 'eth_requestAccounts' });

      // Initialize provider and signer
      const provider = new ethers.providers.Web3Provider(window.ethereum as ExternalProvider);
      const signer = provider.getSigner();

      // Get the user address (signer)
      const userAddress = await signer.getAddress();
      console.log("User Address:", userAddress);
      console.log("Wallet Address:", address);
      
      // Create a contract instance
      const animaContract = new ethers.Contract(animaContractAddress, AnimaAbi, signer);

      // Perform the token transfer
      const tx = await animaContract.transfer(
        yourWalletAddress,
        ethers.utils.parseUnits(next30DaysSubsPrice.toString(), 18) // Anima has 18 decimals
      );
      
      // Wait for the transaction to be mined
      await tx.wait();

      // Show success message
      alert("Transfer completed! Your subscription will be extended shortly!");

      // Call the new API endpoint to update the validUntil date
      const days = 30; // Define the days for the subscription extension
      await axios.post(`${API_BASE_URL}?endpoint=updateValidDate`, {
        address: userAddress,
        days: days
      });

      // Force reload the app
      setTimeout(() => {
        window.location.reload();
      }, 5000);

    } catch (error) {
      console.error("Transaction failed:", error);
      alert("Failed to extend subscription. Please try again.");
    }
  };

  return (
    <div className="fights-data-table" style={{ color: 'black' }}>
      <div style={{ textAlign: 'center', fontWeight: 'bold', backgroundColor: 'rgba(255, 255, 255, 0.2)' }}>
        Battles Stats: UTC time zone
      </div>
      <div className="table-wrapper">
        
        {isLoading ? (
          <div>Loading data, please wait...</div> 
        ) : (
          <table className="fights-data-table__table">
            <thead>
            <tr>
              <th className="date-column"></th>
              <th className="anima-column"></th>
              <th className="fee-column"></th>
              <th className="renown-column">attack</th>
              <th className="passive-renown-column">defense</th>
              <th className="sum-renown-column"></th>
              <th className="fee-column"  style={{ textAlign: 'center' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;avg</th>
              <th className="lootboxes-column"></th>
            </tr>
              <tr>
                <th className="date-column">Date</th>
                <th className="anima-column">Anima</th>
                <th className="fee-column">$TxFee</th>
                <th colSpan={2}>&#916; Renown</th> {/* Delta (Δ) symbol */}
                <th className="sum-renown-column">&#931; Renown</th>
                <th className="fee-column" style={{ textAlign: 'center' }}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ratio</th>
                <th className="lootboxes-column" style={{ textAlign: 'right' }}>LB</th>
                
              </tr>
             
            </thead>
            <tbody>
            {data.map(item => (
              <tr key={item.date}>
                <td className="date-column">{item.date}</td>
                <td className="anima-column">{Math.round(item.animaSum)}</td>
                <td className="fee-column">{item.transactionFeeSum.toFixed(2)}</td>
                <td className="renown-column">{Math.floor(item.renownSum ?? 0)}</td>
                <td className="passive-renown-column">{item.passiveRenown ?? 0}</td>
                <td className="sum-renown-column">{item.renownS ?? 0}</td>
                <td className="fee-column">{item.ratioH === 0 ? '' : (item.ratioH || 0).toFixed(2)}</td>
                <td className="lootboxes-column">{item.lootboxesCount ?? 0}</td>
              </tr>
            ))}
           
              <tr>
                <td className="date-column">Total</td>
                <td className="anima-column">{Math.round(totalAnimaSum)}</td>
                <td className="fee-column">{totalTransactionFeeSum.toFixed(2)}</td>
                <td className="renown-column"></td>
                <td className="passive-renown-column"></td>
                <td className="sum-renown-column"></td>
                <td className="fee-column"></td>
                <td className="lootboxes-column">{totalLootboxesCount}</td>
                
              </tr>
              </tbody>
              <tfoot>
              <tr>
                <th colSpan={3}>(Anima - TxFees)</th>
                <th>{Math.round(totalNetAnimaSum)}</th>
                <th></th>
              </tr>
              {displayAvgLPBoost && (
                <>
                  <tr>
                    <th colSpan={3}>avg LPBoost</th>
                    <th>{(LPBoostPercent * 100).toFixed(0)}%</th>
                    <th></th>
                  </tr>
                  <tr>
                    <th colSpan={3}>(Anima - TxFees - LpBoost)</th>
                    <th>{Math.round(totalNetAnimaLpBoostSum)}</th>
                    <th></th>
                  </tr>
                </>
              )}
              {validUntil && isValidDate(validUntil) && (
                <tr>
                  <th colSpan={4} className="valid-until-column">
                    Subs Expiry Date: {validUntil}
                  </th>
                </tr>
              )}
              {validUntil && isValidDate(validUntil) && (
                <tr>
                  <th colSpan={3}>next 30 Days Subs Price (Anima)</th>
                  <th>{next30DaysSubsPrice}</th>
                </tr>
              )}
              {shouldShowExtendButton && next30DaysSubsPrice > 0 && (
                <tr>
                  <th colSpan={4}>
                    <button onClick={handleExtendSubscription}>
                      Extend Subscription
                    </button>
                  </th>
                </tr>
              )}
            </tfoot>
          </table>
        )}
      </div>
      <style jsx>{`
        .fights-data-table {
          background-color: rgba(255, 255, 255, 0.2);
          padding: 5px;
          border-radius: 6px;
          border: none;
          width: 450px;
         
        }
        .table-wrapper {
          max-height: 180px;
          overflow-y: auto;
        }
        .fights-data-table__table {
          width: 100%;
          text-align: left;
          border-collapse: collapse;
          font-size: 90%;
          font-variant-numeric: tabular-nums;
          
        }
        thead th,
        tbody td,
        tfoot th {
          padding: 2px;
          font-weight: normal;
         
          border: none;
        }

        thead th,
        tfoot th {
         text-align: center;
        }

         tbody td,
         tfoot tr
        {
         text-align: right;
        }

        tbody td{
         font-family: "Courier New", Courier, monospace;
         font-size:90%;
         font-weight:bold;}
       


        .date-column {
          width: 70px;
          text-align: left;
        }

        .anima-column,
        .renown-column,
        .passive-renown-column {
          width: 50px;
          text-align: right;
        }

        .fee-column{
          width: 50px;
          text-align: right;
        }

        


        .sum-renown-column{
          width: 70px;
          text-align: right;
        }

        thead, tbody tr, tfoot tr {
          display: table;
          width: 100%;
          table-layout: fixed;
        }
        tbody {
          display: block;
        }
        .table-wrapper::-webkit-scrollbar {
          width: 12px;
        }
        .table-wrapper::-webkit-scrollbar-track {
          background: rgba(0, 0, 0, 0.2);
        }
        .table-wrapper::-webkit-scrollbar-thumb {
          background: rgba(0, 0, 0, 0.5);
          border-radius: 6px;
        }
        .table-wrapper::-webkit-scrollbar-thumb:hover {
          background: rgba(0, 0, 0, 0.7);
        }
        button {
          height: 26px;
          background-color: #666;
          color: white;
          border-radius: 4px;
          cursor: pointer;
          width: 130px;
        }
        button:hover {
          background-color: #555;
        }
      `}</style>
    </div>
  );
};

export default FightsDataTable;


































