// /renderer/pages/Modal.tsx

import React, { useState } from 'react';

interface ModalProps {
  show: boolean;
  onAddressSubmit: (address: string) => void;
}

const Modal: React.FC<ModalProps> = ({ show, onAddressSubmit }) => {
  const [address, setAddress] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onAddressSubmit(address);
  };

  if (!show) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <p className="prompt-text">Connect to the wallet in the upper right corner. If you enter your wallet address manually, the fight execution feature will not work.</p>
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            placeholder="Enter wallet address"
            required
          />
          <button type="submit">Submit</button>
        </form>
      </div>
      <style jsx>{`
        .modal-overlay {
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
        }
        .modal-content {
          background: white;
          padding: 10px;
          border-radius: 8px;
          box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
          width: 350px;
          background-color: rgba(25, 25, 25, 0.8); 
        }
        .prompt-text {
          font-size: 14px;
          font-weight: normal;
          margin-top: 0;
          margin-bottom: 10px;
          color:white;
          text-align: center;
        }
        form {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        input {
          margin-bottom: 10px;
          padding: 8px;
          
          border-radius: 4px;
          background-color: rgba(255, 255, 255, 0.5); 
          width:330px;
        }
        button {
          padding: 9px 16px; /* 30% taller than original 8px */
          background: #3898FF; 
          color: white;
          border:none;
          
          border-radius: 12px; 
          cursor: pointer;
          font-size: 16px; 
          font-weight: bold;
          width: 130px;
        }
       
      `}</style>
    </div>
  );
};

export default Modal;


