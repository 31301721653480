import React, { createContext, useEffect, useState, ReactNode, useCallback } from 'react';
import { BigNumber, ethers } from 'ethers';
import { useAccount, useProvider } from 'wagmi';
import animaAbi from '../../../eth-sdk/abis/anima.json';

interface AnimaBalanceContextProps {
  balance: BigNumber | null;
  refresh: (address: string) => void;
}

const AnimaBalanceContext = createContext<AnimaBalanceContextProps>({
  balance: null,
  refresh: () => {}
});

interface AnimaBalanceProviderProps {
  children: ReactNode;
}

export const AnimaBalanceProvider = ({ children }: AnimaBalanceProviderProps) => {
  const [balance, setBalance] = useState<BigNumber | null>(null);
  const { address } = useAccount();
  const provider = useProvider();

  const fetchBalance = useCallback(async (addr: string) => {
    if (addr) {
      const contract = new ethers.Contract(
        '0xccd05a0fcfc1380e9da27862adb2198e58e0d66f',
        animaAbi,
        provider
      );
      const balance = await contract.balanceOf(addr);
      setBalance(balance);
    }
  }, [provider]);

  useEffect(() => {
    if (address) {
      fetchBalance(address);
    }
  }, [address, fetchBalance]);

  return (
    <AnimaBalanceContext.Provider value={{ balance, refresh: fetchBalance }}>
      {children}
    </AnimaBalanceContext.Provider>
  );
};

export const useAnimaBalance = () => React.useContext(AnimaBalanceContext);






