import React, { useEffect, useState } from 'react';
import { useRarityItemBalance } from '../../dai/context/RarityItemBalance.context';
import { useAnimaBalance } from '../../dai/context/AnimaBalance.context'; // Import the hook
import { PERIPHERALS } from '../constants/constants';

const rarityLevels = ['Common', 'Rare', 'Epic', 'Legendary'];
const slots = ['Head', 'Chest', 'Hand', 'Accessory'];

interface RarityItemTableProps {
  refreshTrigger: boolean;
  address: string;
}

const RarityItemTable: React.FC<RarityItemTableProps> = ({ refreshTrigger , address}) => {
  const { balances, loading, refresh } = useRarityItemBalance();
  const { refresh: refreshAnima } = useAnimaBalance(); // Use the refresh function from AnimaBalance

  const [tableData, setTableData] = useState<{ [slot: string]: { [rarity: string]: number } }>({
    Head: { Common: 0, Rare: 0, Epic: 0, Legendary: 0 },
    Chest: { Common: 0, Rare: 0, Epic: 0, Legendary: 0 },
    Hand: { Common: 0, Rare: 0, Epic: 0, Legendary: 0 },
    Accessory: { Common: 0, Rare: 0, Epic: 0, Legendary: 0 },
  });

  const [refreshing, setRefreshing] = useState(false); // Add this state variable

  useEffect(() => {
    console.log('Refreshing RarityItemTable');
    setRefreshing(true); // Set refreshing to true before calling refresh
    Promise.all([refresh(address), refreshAnima(address)]).then(() => setRefreshing(false)); // Call both refresh functions
  }, [address, refresh, refreshAnima, refreshTrigger]);

  useEffect(() => {
    console.log('Updating table data with balances:', balances);
    const newTableData: { [slot: string]: { [rarity: string]: number } } = {
      Head: { Common: 0, Rare: 0, Epic: 0, Legendary: 0 },
      Chest: { Common: 0, Rare: 0, Epic: 0, Legendary: 0 },
      Hand: { Common: 0, Rare: 0, Epic: 0, Legendary: 0 },
      Accessory: { Common: 0, Rare: 0, Epic: 0, Legendary: 0 },
    };

    for (const id in balances) {
      const item = PERIPHERALS[id];
      if (item) {
        const rarity = item.attributes.find(attr => attr.trait_type === 'Rarity')?.value;
        const slot = item.attributes.find(attr => attr.trait_type === 'Slot')?.value;

        if (rarity && slot) {
          newTableData[slot][rarity] += balances[id];
        }
      }
    }

    setTableData(newTableData);
  }, [balances]);

  return (
    <div className="rarity-item-table" style={{ color: 'black' }}>
      <div style={{ textAlign: 'center', fontWeight: 'bold', backgroundColor: 'rgba(255, 255, 255, 0.2)' }}>
        Peripherals in wallet
        <span>{refreshing && ' (refreshing...)'}</span> {/* Show refreshing indicator */}
      </div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <table style={{ width: '100%', textAlign: 'left', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              <th></th>
              {slots.map(slot => (
                <th key={slot}>{slot}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rarityLevels.map(rarity => (
              <tr key={rarity}>
                <td>{rarity}</td>
                {slots.map(slot => (
                  <td key={`${rarity}-${slot}`}>{tableData[slot][rarity]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <style jsx>{`
        .rarity-item-table {
          background-color: rgba(255, 255, 255, 0.2); /* Transparent background */
          padding: 5px;
          border-radius: 6px;
          border:none;
         
          
        }
        table, th, td {
          border: none;
        }
        th, td {
          padding: 2px;
          font-weight: normal; /* Remove bold styling */
          text-align: center; /* Center align text */
          font-size:90%;
        }
      `}</style>
    </div>
  );
};

export default RarityItemTable;







