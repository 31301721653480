import { getDefaultWallets } from '@rainbow-me/rainbowkit';
import { chain, configureChains, createClient } from 'wagmi';
import { publicProvider } from 'wagmi/providers/public';
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';

// Returns the RPC for the network. If it's not configured return undefined
function getRPCforChainId(id: number): string | undefined {
  switch (id) {
    case chain.arbitrum.id:
      return process.env.NEXT_PUBLIC_RPC_PROVIDER_ARBITRUM;
    default:
      return undefined;
  }
}

export const { chains, provider } = configureChains(
  [chain.arbitrum],
  [
    jsonRpcProvider({
      rpc: chain => {
        const rpc = getRPCforChainId(chain.id);
        return rpc ? { http: rpc } : null;
      }
    }),
    publicProvider()
  ]
);

const { connectors } = getDefaultWallets({
  appName: 'Web3 Demo',
  chains
});

export const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider
});




