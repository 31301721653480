import React from 'react';
import { Header } from './Header';
//import { Footer } from './Footer';

export function Layout({
  children
}: {
  children: React.ReactNode;
  metaDescription?: string;
}): React.ReactElement {
  return (
    <React.Fragment>
      <div className="body">
        <Header />
        <div className="main">{children}</div>
      </div>
      <style jsx>{`
        .body {
          min-height: 100vh;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 0;
          max-width: 100%;
          margin: 0 auto;
          z-index:1;
        }

        .main {
          flex: 1; /* Zapewnia, �e main zajmuje pozosta�� dost�pn� przestrze� */
          max-width: 1400px;
          padding: 30px;
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          z-index:1;
        }
      `}</style>
    </React.Fragment>
  );
}
