// /renderer/pages/index_prod.tsx

import Adventurers from '@/modules/adventurers/components/Adventurers';
import { Layout } from '@/modules/layout/components/Layout';
import { useEffect, useState } from 'react';
import { useAccount } from 'wagmi';
import Modal from './Modal';
import { ethers } from 'ethers';
import globallyStakedTokenCalculatorAbi from '@/abis/burncalculator.json';
import axios from 'axios';  // Import axios for making API requests

import {API_BASE_URL} from '../modules/adventurers/constants/constants' ;

// Define a global variable to hold the animaBurnPercentage
export let animaBurnPercentage: number | null = 0;

// Define a global variable to hold ALLOWED_ADDRESSES
export let ALLOWED_ADDRESSES: any[] = [];

// Define the contract address as a constant
const GLOBALLY_STAKED_TOKEN_CALCULATOR_ADDRESS = '0x0dEc32a5a0E778b110946aD6fb84402d53F89963';
const ALLOWED_ADDRESSES_ENDPOINT = `${API_BASE_URL}?endpoint=allowed_addresses`;

export default function Home() {
  const { address: connectedAddress, isConnected } = useAccount();
  const [address, setAddress] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleAddressSubmit = (submittedAddress: string) => {
    setAddress(submittedAddress);
    setShowModal(false);
  };

  // Function to fetch animaBurnPercentage
  const fetchAnimaBurnPercentage = async () => {
    const provider = new ethers.providers.JsonRpcProvider('https://arb1.arbitrum.io/rpc');
    const contract = new ethers.Contract(
      GLOBALLY_STAKED_TOKEN_CALCULATOR_ADDRESS,
      globallyStakedTokenCalculatorAbi,
      provider
    );

    try {
      const currentEpoch = Math.floor(Date.now() / (24 * 60 * 60 * 1000));
      const result = await contract.globallyStakedAverageView(currentEpoch, 30, true);
      const percentage = parseFloat(ethers.utils.formatUnits(result.percentage, 18));
      animaBurnPercentage = percentage * 10 ** 13; // Multiply as requested
      console.log('Fetched animaBurnPercentage:', animaBurnPercentage);
    } catch (error) {
      console.error('Error fetching Anima Burn Rate Percentage:', error);
      animaBurnPercentage = null;
    }
  };

  // Function to fetch ALLOWED_ADDRESSES from backend
  const fetchAllowedAddresses = async () => {
    if (address) { // Ensure address is valid
      try {
        const response = await axios.get(`${ALLOWED_ADDRESSES_ENDPOINT}&address=${address}`);
        ALLOWED_ADDRESSES = response.data; // Set the ALLOWED_ADDRESSES variable
        console.log('Fetched ALLOWED_ADDRESSES:', ALLOWED_ADDRESSES);
      } catch (error) {
        console.error('Error fetching ALLOWED_ADDRESSES:', error);
        ALLOWED_ADDRESSES = [];
      }
    }
  };

  useEffect(() => {
    // Fetch both animaBurnPercentage and ALLOWED_ADDRESSES synchronously only when the address is available
    const fetchData = async () => {
      try {
        await fetchAllowedAddresses(); // Fetch only if address is set
        await fetchAnimaBurnPercentage();
        setLoading(false); // Stop loading after data is fetched
      } catch (error) {
        console.error('Error during data fetch:', error);
      }
    };

    fetchData();
  }, [address]); // Only run this effect when 'address' changes

  useEffect(() => {
    if (isConnected && connectedAddress) {
      setAddress(connectedAddress);
      setShowModal(false);  // Close the modal if the wallet is connected
    } else {
      setShowModal(true);  // Show the modal if no address is connected
    }
  }, [isConnected, connectedAddress]);

  return (
    <Layout>
      <main>
        <div className="page-content">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <>
              {address ? <Adventurers address={address} /> : <p>Address not found</p>}
              <Modal show={showModal} onAddressSubmit={handleAddressSubmit} />
            </>
          )}
        </div>
      </main>
    </Layout>
  );
}


