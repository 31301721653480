import axios from 'axios';
import { LootboxType } from '../types/lootboxes';

const GET_LOOTBOXES_QUERY = `
  query MyQuery {
    listing1: sales(first:1 where:{token_ends_with:"-1",collection:"0x6725ce94f1991e6ec0d4dafd586e9c56099db2d2"}, orderBy:timestamp, orderDirection:desc) {
      id
      token {
        id
        tokenId
      }
      pricePerItem
      quantity
    }
    listing2: sales(first:1 where:{token_ends_with:"-2",collection:"0x6725ce94f1991e6ec0d4dafd586e9c56099db2d2"}, orderBy:timestamp, orderDirection:desc) {
      id
      token {
        id
        tokenId
      }
      pricePerItem
      quantity
    }
    listing3: sales(first:1 where:{token_ends_with:"-3",collection:"0x6725ce94f1991e6ec0d4dafd586e9c56099db2d2"}, orderBy:timestamp, orderDirection:desc) {
      id
      token {
        id
        tokenId
      }
      pricePerItem
      quantity
    }
    listing4: sales(first:1 where:{token_ends_with:"-4",collection:"0x6725ce94f1991e6ec0d4dafd586e9c56099db2d2"}, orderBy:timestamp, orderDirection:desc) {
      id
      token {
        id
        tokenId
      }
      pricePerItem
      quantity
    }
  }
`;

//my api key:a6397e664bae6aa7855f1f9fdcfdbfd6

const fetchLootboxes = async (): Promise<LootboxType[]> => {
  try {
    const response = await axios.post(
      'https://gateway-arbitrum.network.thegraph.com/api/a6397e664bae6aa7855f1f9fdcfdbfd6/subgraphs/id/DUiGDuugECaB6vgaWTnfqqYt35XukRDKDfYB2Yr1Erde', 
      {
      query: GET_LOOTBOXES_QUERY,
    });

    // Sprawdź strukturę odpowiedzi
    console.log('API Response:', response.data);

    if (
      response.data &&
      response.data.data &&
      response.data.data.listing1 &&
      response.data.data.listing2 &&
      response.data.data.listing3 &&
      response.data.data.listing4
    ) {
      const listings = [
        ...response.data.data.listing1,
        ...response.data.data.listing2,
        ...response.data.data.listing3,
        ...response.data.data.listing4,
      ];

      console.log('getLootboxes', listings);

      return listings.map((listing: any) => ({
        id: listing.id,
        lootboxId: listing.token.tokenId,
        pricePerItem: listing.pricePerItem,
        quantity: listing.quantity,
      }));
    } else {
      throw new Error('Unexpected API response structure');
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    return [];
  }
};

export default fetchLootboxes;
