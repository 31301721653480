import axios from 'axios';
import { API_BASE_URL } from '../constants/constants';
//const API_BASE_URL='https://staging.getanima.org';

interface Prices {
  [key: string]: {
    usd: number;
  };
}

export const fetchPrices = async (): Promise<Prices> => {
  try {
    const response = await axios.get(API_BASE_URL, {
      params: {
        endpoint: 'coingecko'
      }
    });

    return response.data;
  } catch (error) {
    throw new Error('Failed to fetch prices');
  }
};



